import { createGlobalStyle } from "styled-components"
import bg from "../assets/images/bg.png"

const GlobalStyles = createGlobalStyle`
  :root {
    --red: #ED1D69;
    --black: #252525;
    --white: #DEDEDE;
    --navigation-height: 80px;
    --small-navigation-height: 60px;
    --content-size: 1600px;
    --content-padding: 0 20px;
    --inside-content-padding: 0 200px;
    @media (max-width: 1450px) {
      --inside-content-padding: 0 150px;
    }
    @media (max-width: 1300px) {
      --inside-content-padding: 0 100px;
    }
    @media (max-width: 1100px) {
      --inside-content-padding: var(--content-padding);
    }
  }

  html {
    background-image: url(${bg});
    background-repeat: repeat; 
    font-size: 10px;
    position: relative;
    &:after{
      content: '';
      height:32px;
      background: var(--black);
      position: absolute;
      bottom:0;
      left:0;
      width:100%;
    }
  }

  .content {
    max-width: var(--content-size);
  }

  button {
    background: var(--red);
    color: var(--white);
    border: 1px solid var(--red);
    padding: 10px 15px;
    cursor: pointer;
    transition: 0.2s ease-in;
    &:hover {
        background: var(--black);
    }
  }

  ${"img[src*=base64\\,]"} {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  img {
    max-width: 100%;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--red) var(--white);
  }
  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--red) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }


  .no-arrow-input {
  -moz-appearance: textfield;
}
.no-arrow-input::-webkit-inner-spin-button {
  display: none;
}
.no-arrow-input::-webkit-outer-spin-button,
.no-arrow-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.page{
  position:relative;
  width: 100%;
  max-width: var(--content-size);
  margin: var(--navigation-height) auto 0 auto;
  z-index: 1;
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
    .content{
      padding: var(--inside-content-padding);
    }
}
`

export default GlobalStyles
