import React from "react"
import "normalize.css"
import GlobalStyles from "../styles/GlobalStyles"
import Typography from "../styles/Typography"

export default function Layout({ children }) {
  // if we don't want to pass the layout manually to all pages, we can use gatsby-browser.js
  // 6 - creating layouts in gatsby

  return (
    <>
      <GlobalStyles />
      <Typography />
      {children}
    </>
  )
}
