import React, { useState } from "react"

const DataContext = React.createContext()

export function DataProvider({ children }) {
  // state here
  const [contextData, setContextData] = useState({
    isMobileMenuOpen: false,
    isSmallNavbar: false,
  })
  return (
    <DataContext.Provider value={[contextData, setContextData]}>
      {children}
    </DataContext.Provider>
  )
}

export default DataContext
