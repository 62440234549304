import { createGlobalStyle } from "styled-components"

const Typography = createGlobalStyle`
  @font-face {
    font-family: SourceSP;
    src: asset-url("/static/fonts/source-sans-pro.woff");
  }

  html {
    font-family: SourceSP, sans-serif;
    color: var(--white);
  }

  body {
    font-size: 1.5rem;
    margin: 0;
  }

  p {
    letter-spacing: 0.5px;
    margin: 0;
    color: var(--white);
    font-weight: 300;
    font-style: normal;
    &::selection {
        color: var(--red);
    }
  }

  a {
    color: var(--red);
    text-decoration: none;
    transition: 0.2s ease-in;
    &:hover {
        color:var(--white);
        }
    }

    ul {
    margin:0;
    padding:0; 
    font-size: 1.4rem;
  }

  li {
     list-style-type: none;
     letter-spacing: 0.5px;
    }

  blockquote {
    &::selection {
        color: var(--red);
    }
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  strong {
    font-weight: bold!important;
  }

  h1,h2,h3,h4,h5,h6 {
    font-weight: 400;
    margin: 0;
    &::selection {
        color: var(--red);
    }
  }


  .center {
    text-align: center;
  }

  .tilt {
    transform: rotate(-2deg);
  }
`

export default Typography
