import React from "react"
import Layout from "./src/components/Layout"
import { DataProvider } from "./src/components/DataContext"

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>
}

export function wrapRootElement({ element }) {
  return <DataProvider>{element}</DataProvider>
}
